@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;300;500&family=Barlow:wght@300;400;700&display=swap');
@import 'glitch.scss';

h1,
p {
}

/* body {
  background:
      linear-gradient(-90deg, rgba(255,255,255,.04) 1px, transparent 1px),
      linear-gradient(rgba(146, 86, 86, 0.04) 1px, transparent 1px), 
      linear-gradient(-90deg, rgba(255, 255, 255, .02) 1px, transparent 1px),
      linear-gradient(rgba(255,255,255,.02) 1px, transparent 1px),
      linear-gradient(transparent 3px, #000 3px, #000 78px, transparent 78px),
      linear-gradient(-90deg, rgb(48, 48, 48) 1px, transparent 1px),
      linear-gradient(-90deg, transparent 3px, #000 3px, #000 78px, transparent 78px),
      linear-gradient(rgb(48, 48, 48) 1px, transparent 1px),
      #000;
  background-size:
      4px 4px,
      4px 4px,
      80px 80px,
      80px 80px,
      80px 80px,
      80px 80px,
      80px 80px,
      80px 80px;
} */

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Barlow Condensed', sans-serif;
  background-size: 100px 100px;
  background-image: radial-gradient(
      circle,
      rgb(36, 36, 36) 1px,
      rgba(0, 0, 0, 0.9) 1px
    ),
    url(https://grainy-gradients.vercel.app/noise.svg);
  background-position: 50px 50px;
  background-color: #313131;
  color: #fff;
}

.logo {
  position: fixed;
  top: 8%;
  left: 5%;
  z-index: 1;
  img {
    max-width: 100px;
  }
}

.scroller {
  z-index: 2;
  position: fixed;
  bottom: 0;
  height: 50%;
  right: 5%;
  font-family: 'Barlow', sans-serif;
  .active {
    color: #fff;
  }
  // transform: translateY(-10%);
}

.scroller ol {
  margin: 0;
}

.scroller li {
  list-style: none;
  margin: 0 0 10vh;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #fff;
  }
}

.scroller-active,
.scroller-link {
  transition: width 0.25s ease-in-out;
  width: 0px;
  height: 25px;
  position: fixed;
  right: 40px;
  top: 50%;
  background-image: linear-gradient(
    -45deg,
    rgba(74, 131, 255, 0.5) 25%,
    transparent 25%,
    transparent 50%,
    rgba(74, 131, 255, 0.5) 50%,
    rgba(74, 131, 255, 0.5) 75%,
    transparent 75%,
    transparent
  );
  background-size: 4px 4px;
  z-index: 1;
  transform: translateY(-50%);
}

.section {
  height: 100vh;
}

.zap {
  & .scroller-active {
    width: 5%;
  }
}

.bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
  }
}
#panel02 video, #panel04 video {
  filter: hue-rotate(90deg);
}
#panel03 video {
  filter: hue-rotate(10deg);
}

.panel {
  height: 100vh;
  position: relative;
}

.content {
  position: relative;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  max-width: 40%;
  z-index: 2;
}

.sticky {
  background-color: red;
  width: 100%;
  .item {
    position: relative;
    z-index: 2;
    padding: 30px;
  }
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
  font-weight: 200;
}

.explore {
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  a {
    cursor: pointer;
  }
}

.content h1 {
  text-align: left;
  font-weight: 300;
}

.preview {
  position: absolute;
  right: 19%;
  top: 10%;
  .preview-inner {
    border-radius: 30px;
    border: 30px solid rgba(0, 0, 0, 0.9);
    overflow-y: scroll;
    max-width: 500px;
    max-height: 75vh;
    box-shadow: 0 0 0 8px hsl(0, 0%, 13%), 0 0 0 10px hsl(0, 0%, 26%);

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      border-radius: 10px;
      width: 100%;
    }
  }
}

.index {
  font-family: 'Barlow', sans-serif;
}

.link-text {
  position: relative;
  z-index: 2;
  color: #fff;
  letter-spacing: 2px;
  font-family: 'Barlow', sans-serif;
  &:hover {
    color: #e400ff;
  }
}

.scroller-link {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 150px;
}

.footer-social {
  position: absolute;
  bottom: 8%;
  left: 5%;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    margin: 0;
    a {
      filter: invert(1);
      $icon-size: 20px;
      background: url('../public/discord.svg');
      width: $icon-size;
      height: $icon-size;
      background-size: $icon-size $icon-size;
      text-indent: -20000px;
      display: block;
      margin-right: 15px;
    }
    &.telegram a {
      background: url('../public/telegram.svg');
    }
    &.twitter a {
      background: url('../public/twitter.svg');
    }
  }
}

.copyright {
  position: absolute;
  left: 25%;
  width: 50%;
  bottom: 8%;
  margin: 0;
  transform: translateX(25%);
}

#panel01 .scroller-active {
  filter: hue-rotate(325deg);
}

#panel01 .link-text:hover,
#panel03 .link-text:hover {
  color: rgb(0, 157, 230);
}

#panel02 .scroller-active, #panel04 .scroller-active {
  filter: hue-rotate(78deg);
}

#panel05 .link-text {
  margin-bottom: 40px;
  text-transform: uppercase;
  display: block;
  text-decoration: none;
  span {
    font-size: 24px;
  }
}

address,
p {
  font-family: 'Barlow', sans-serif;
  font-variant: normal;
  font-style: normal;
  line-height: 2em;
  margin: 30px 0;
  font-size: 11px;
}

@media (min-width: 1800px) {
  #panel01,
  #panel03 {
    .content {
      left: 40%;
    }
  }

  #panel02 {
    .preview {
      left: 20%;
      right: auto;
    }
    .content {
      left: 75%;
    }
  }
}

@media (min-width: 800px) and (max-width: 1800px) {
  #panel01,
  #panel03 {
    .content {
      left: 40%;
    }
  }

  #panel02, #panel04 {
    .preview {
      left: 20%;
      right: auto;
    }
    .content {    
      left: auto;
      right: 10%;
      transform: translate(150%, -50%);
    }
  }

  .preview {
    .preview-inner {
      max-width: 350px;
    }
  }
  .content h3 {
    max-width: 400px;
  }
}


@media (min-width: 800px) and (max-width: 1200px) {
  #panel01,
  #panel03 {
    .content {
      left: 25%;
    }
    .preview {
      right: 12%;
    }
  }

  #panel02, #panel04 {
    .content {
      // left: 85%;
    }
    .preview {
      left: 10%
    }
  }
  .preview {
    .preview-inner {
      // max-width: 250px;
    }
  }
}

@media (max-width: 800px) {
  .logo {
    left:40px;
  }
  .content {
    max-width: 100%;
    padding-top:40px;
    left: auto !important;
    right: auto !important;
  }
  .preview {
    left: auto !important;
    right: auto !important;
  }

  #panel00 .content {
    margin: 100px 30px 30px;
  }
  .preview {
    position: relative;
    width: 80%;
    margin: 10%;
    top: 0;
    left: 0;
    .preview-inner {
      height: 40vh;
      border: 10px solid rgba(0, 0, 0, 0.9);
      box-shadow: 0 0 0 2px #212121, 0 0 0 4px #424242;
      border-radius: 10px;
    }
  }
  .content {
    position: relative;
    width: 79%;
    top: 0;
    left: 0;
    transform: none;
    margin: 30px 30px 0;
    h3 {
      max-width: 400px;
    }
  }
  .copyright,
  .footer-social {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    transform: none;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
}
